<template>
  <div class="m-5 bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6 flex justify-between">
      <div>
        <h3 class="text-lg leading-6 font-medium body__header--text">
          {{ this.mailFilterDetail.name }}
        </h3>
      </div>
      <div>
        <button
          type="button"
          @click="
            $emit(
              'open-edit-form',
              this.mailFilterDetail.id,
              this.mailFilterDetail.name,
              this.mailFilterDetail.combination,
              this.mailFilterDetail.redirectionType,
              this.mailFilterDetail.redirectionId,
              this.mailFilterDetail.priorityId,
              this.mailFilterDetail.categoryId,
              this.mailFilterDetail.subCategoryId,
              this.mailFilterDetail.autoClose,
              this.mailFilterDetail.dontCreateTicket,
              'Update Email Filter'
            )
          "
          class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white body__table-pagination--button"
        >
          Edit
        </button>
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500 flex">
            <span class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                /></svg
            ></span>
            Priority
          </dt>
          <dd class="mt-1 ml-6 text-sm text-gray-900">
            {{ this.mailFilterDetail.priorityId }}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500 flex">
            <span class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                /></svg></span
            >Type of redirect
          </dt>
          <dd class="mt-1 ml-6 text-sm capitalize text-gray-900">
            {{ this.mailFilterDetail.redirectionType }}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500 flex">
            <span class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                /></svg
            ></span>
            Category
          </dt>
          <dd class="mt-1 ml-6 capitalize text-sm text-gray-900">
            {{ this.category }}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500 flex">
            <span class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                /></svg
            ></span>
            Combination
          </dt>
          <dd class="mt-1 ml-6 text-sm text-gray-900 uppercase">
            {{ this.mailFilterDetail.combination }}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500 flex">
            <span class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                /></svg
            ></span>
            Redirect to
          </dt>
          <dd v-if="this.getRedirect()" class="mt-1 ml-6 text-sm text-gray-900">
            Equipe {{ this.getRedirect().name }}
          </dd>
          <dd v-else class="mt-1 ml-6 text-sm text-gray-900">
            No team
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500 flex">
            <span class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                /></svg
            ></span>
            Sub-category
          </dt>
          <dd class="mt-1 ml-6 text-sm text-gray-900">
            {{ this.subCategory.name }}
          </dd>
        </div>

        <div class="sm:col-span-3">
          <dt class="text-sm font-medium text-gray-500">
            Conditions
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6"
                >
                  <div
                    class="border overflow-hidden border-b border-gray-200 sm:rounded-lg"
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-200">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Filtered on
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Value
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(FilterCondition, FilterConditionsIdx) in this
                            .mailFilterDetail.mail_filter_conditions"
                          :key="FilterCondition.id"
                          :class="
                            FilterConditionsIdx % 2 === 0
                              ? 'bg-white'
                              : 'bg-gray-100'
                          "
                        >
                          <td
                            class="px-6 py-4 whitespace-nowrap capitalize text-sm font-medium text-gray-900"
                          >
                            {{ FilterCondition.object }}
                          </td>
                          <td
                            class="px-6 py-4 whitespace-nowrap capitalize text-sm text-gray-500"
                          >
                            {{ FilterCondition.condition }}
                          </td>
                          <td
                            class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                          >
                            {{ FilterCondition.value }}
                          </td>
                          <td class="text-right">
                            <EditConditionEmailFilters
                              :id="FilterCondition.id"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </dd>
        </div>
        <div class="sm:col-span-3">
          <dt class="text-sm font-medium text-gray-500">
            Add new conditions
          </dt>

          <form
            @submit.prevent="
              this.createNewCondition(this.mailFilterDetail.id, msg)
            "
          >
            <dl class="grid grid-cols-9">
              <dd class="col-span-2 mt-1 mr-1 text-sm text-gray-900">
                <div>
                  <label
                    for="location"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Filtered on</label
                  >
                  <select
                    v-model="this.form.object"
                    required
                    id="location"
                    name="location"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                  >
                    <option disabled>Select</option>
                    <option value="expeditor">Expeditor</option>
                    <option value="subject">Subject</option
                    ><option value="body">Body</option>
                  </select>
                </div>
              </dd>
              <dd class="col-span-2 mt-1 mx-1 text-sm text-gray-900">
                <div>
                  <label
                    for="location"
                    class="block text-sm font-medium text-gray-700"
                    >Type</label
                  >
                  <select
                    v-model="this.form.condition"
                    required
                    id="location"
                    name="location"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 sm:text-sm rounded-md"
                  >
                    <option disabled>Select</option>
                    <option value="est">IS EQUAL TO</option>
                    <option value="contient">INCLUDE</option>
                  </select>
                </div>
              </dd>
              <dd
                class="col-span-4 col-start-5 col-end-8 mt-1 ml-1 text-sm text-gray-900"
              >
                <div>
                  <label
                    for="email"
                    class="block text-sm font-medium text-gray-700"
                    >Value</label
                  >
                  <div class="flex mt-1">
                    <div class="w-3/4 mr-5">
                      <textarea
                        v-model="this.form.value"
                        required
                        name="value"
                        id="value"
                        class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
                      ></textarea>
                    </div>
                    <div>
                      <button
                        type="submit"
                        class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white body__table-pagination--button"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </form>
        </div>
      </dl>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";

import ConfirmationDialog from "../../ConfirmationDialog.vue";
import EditConditionEmailFilters from "./EditConditionEmailFilters.vue";
export default {
  name: "EmailFiltersDetail",
  props: ["mailFilterDetail", "teams", "users", "categories"],
  components: { EditConditionEmailFilters, ConfirmationDialog },
  data() {
    return {
      category: "",
      subCategories: [],
      subCategory: "",
      form: { object: "Select", condition: "Select", value: "" },
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
    };
  },
  methods: {
    createNewCondition(id, msg) {
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie("API")}/api/v1/mailFilterConditions`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          mail_filter_id: id,
          object: this.form.object,
          condition: this.form.condition,
          value: this.form.value,
        },
      };

      axios
        .request(options)
        .then((res) => {
          msg = "Condition created successfully";
          this.sendMessage(msg);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        });
    },
    getRedirect() {
      if (this.mailFilterDetail.redirectionType === "team") {
        const team = this.teams.find(
          (el) => el.id === this.mailFilterDetail.redirectionId
        );
        return team;
      } else {
        const user = this.users.find(
          (el) => el.id === this.mailFilterDetail.redirectionId
        );
        return user;
      }
    },
    async getSubCategories() {
      if (this.mailFilterDetail.categoryId) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/ticketCategories/${
              this.mailFilterDetail.categoryId
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.category = res.data.name;
          this.subCategories = res.data.ticket_sub_categories;
          this.isLoading = false;
          const subCategory = this.subCategories.find(
            (el) => el.id === this.mailFilterDetail.subCategoryId
          );
          this.subCategory = subCategory;
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
          this.isLoading = false;
        }
      }
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {
    this.getRedirect();
    this.getSubCategories();
  },
};
</script>
