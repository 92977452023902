<template>
  <td class="pr-3 pl-3">
    <div class="inline-flex">
      <a
        @click="
          deleteCondition(
            id,
            `Delete Filter Condition`,
            'Do you wish to continue?',
            'Continue',
            'Go back',
            'Filter Condition deleted successfully'
          )
        "
        href="javascript:void(0)"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`
        "
      >
        <HoverTooltip text="Delete"/>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg></a>
    </div>
  </td>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";

import ConfirmationDialog from "../../ConfirmationDialog.vue";
import AskConfirmationDialog from "../../AskConfirmationDialog.vue";
import HoverTooltip from "../../HoverTooltip.vue";

export default {
  props: ["id", "name", "open", "customerId", "contactId"],
  components: {
    ConfirmationDialog,
    AskConfirmationDialog,
    HoverTooltip,
  },
  data() {
    return {
      closePutForm: true,
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
    };
  },
  methods: {
    async deleteCondition(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(
            `${this.$cookie.getCookie("API")}/api/v1/mailFilterConditions/${id}`
          )
          .then((res) => {
            if (res) {
              this.sendMessage(successMsg);
            }
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              this.$store.dispatch("token", null);
              this.$router.push("/login");
            }
          });
      }
    },
    cancelPutForm() {
      this.closePutForm = !this.closePutForm;
      this.$forceUpdate();
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style></style>
