<template>
  <div class="z-10">
    <div class="w-full">
      <form
        @submit.prevent="
          [
            this.title === 'New Email Filter'
              ? createNewFilter(msg)
              : updateEmailFilter(this.mailFilterEdited[0], msg),
          ]
        "
        class="space-y-8 divide-y divide-gray-200 m-2"
      >
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5 ml-5">
          <div class="space-y-6 sm:pt-5 sm:space-y-5">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ this.title }}
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Create by complete informations below.
              </p>
            </div>
            <div class="space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="this.form.name"
                    required
                    type="text"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="conditions"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Conditions
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="this.form.combination"
                    required
                    id="conditions"
                    name="conditions"
                    class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="">Select</option>
                    <option value="and">AND</option>
                    <option value="or">OR</option>
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="conditions"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Redirect to
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="this.form.redirectionType"
                    id="conditions"
                    name="conditions"
                    class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="">Select</option>
                    <option value="team">Team</option>
                    <option value="user">User</option>
                  </select>
                  <div v-if="this.form.redirectionType === 'team'">
                    <label
                      for="conditions"
                      class="block text-sm font-medium capitalize text-gray-700 sm:mt-px sm:pt-2"
                    >
                      {{ this.form.redirectionType }}
                    </label>
                    <select
                      v-model="this.form.redirectionId"
                      id="conditions"
                      name="conditions"
                      class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      <option>Select</option>
                      <option
                        v-for="team in teams"
                        :key="team.id"
                        :value="team.id"
                        >{{ team.name }}</option
                      >
                    </select>
                  </div>
                  <div v-if="this.form.redirectionType === 'user'">
                    <label
                      for="conditions"
                      class="block text-sm font-medium capitalize text-gray-700 sm:mt-px sm:pt-2"
                    >
                      {{ this.form.redirectionType }}
                    </label>
                    <select
                      v-model="this.form.redirectionId"
                      id="conditions"
                      name="conditions"
                      class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      <option>Select</option>
                      <option
                        v-for="user in users"
                        :key="user.id"
                        :value="user.id"
                        >{{ user.name }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="conditions"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Priority
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="this.form.priorityId"
                    id="conditions"
                    name="conditions"
                    class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option>Select</option>
                    <option value="4">Critical</option>
                    <option value="3">High</option>
                    <option value="2">Medium</option>
                    <option value="1">Low</option>
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="conditions"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Category
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="this.form.categoryId"
                    @change="this.getSubCategories(this.form.categoryId)"
                    id="conditions"
                    name="conditions"
                    class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option>Select</option>
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                      >{{ category.name }}</option
                    >
                  </select>

                  <div v-if="this.form.categoryId">
                    <label
                      for="conditions"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Sub Category
                    </label>
                    <select
                      v-model="this.form.subCategoryId"
                      id="conditions"
                      name="conditions"
                      class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      <option disabled>Select</option>
                      <option
                        v-for="subCategory in subCategories"
                        :key="subCategory.id"
                        :value="subCategory.id"
                        >{{ subCategory.name }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5"
          >
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Tickets Options
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Choose up to twp options for your filter.
              </p>
            </div>
            <div class="space-y-6 sm:space-y-5 divide-y divide-gray-200">
              <div class="pt-6 sm:pt-5">
                <div role="group" aria-labelledby="label-email">
                  <div class="sm:items-baseline">
                    <div class="mt-4 sm:mt-0">
                      <div class="flex space-x-6">
                        <div class="relative flex items-start mr-48 ml-12">
                          <div class="flex items-center h-5">
                            <input
                              v-model="this.form.autoClose"
                              id="comments"
                              name="comments"
                              type="checkbox"
                              class="h-4 w-4 primary_text border-gray-300 rounded"
                            />
                          </div>
                          <div class="ml-3 text-sm">
                            <label
                              for="comments"
                              class="font-medium text-gray-700"
                              >Open and Close</label
                            >
                            <p class="text-gray-500">
                              Open a ticket and close it straight away.
                            </p>
                          </div>
                        </div>
                        <div>
                          <div class="relative flex items-start">
                            <div class="flex items-center h-5">
                              <input
                                v-model="this.form.dontCreateTicket"
                                id="candidates"
                                name="candidates"
                                type="checkbox"
                                class="h-4 w-4 primary_text border-gray-300 rounded"
                              />
                            </div>
                            <div class="ml-3 text-sm">
                              <label
                                for="candidates"
                                class="font-medium text-gray-700"
                                >None</label
                              >
                              <p class="text-gray-500">
                                Don't create ticket.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <a
              href="javascript:void(0)"
              v-on:click="$emit('closeForm')"
              class="rounded bg-white py-2 px-4 border border-gray-300  shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              class="rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white"
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import ConfirmationDialog from "../../ConfirmationDialog.vue";

export default {
  name: "NewEmailFilters",
  props: [
    "categories",
    "mailFilterEdited",
    "selected",
    "teams",
    "title",
    "users",
  ],
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    ConfirmationDialog,
  },

  data() {
    return {
      form: {
        name: "",
        combination: "",
        redirectionType: "",
        redirectionId: "",
        priorityId: "Select",
        categoryId: "",
        subCategoryId: "",
        storeAttachments: "",
        path: "",
        order: 100,
        autoClose: false,
        dontCreateTicket: false,
      },
      subCategories: [],
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
    };
  },
  methods: {
    createNewFilter(msg) {
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie("API")}/api/v1/mailFilters`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          name: this.form.name,
          combination: this.form.combination,
          redirectionType: this.form.redirectionType,
          redirectionId: this.form.redirectionId,
          priorityId: this.form.priorityId,
          categoryId: this.form.categoryId,
          subCategoryId: this.form.subCategoryId,
          storeAttachments: this.form.storeAttachments,
          path: this.form.path,
          order: this.form.order,
          autoClose: this.form.autoClose,
          dontCreateTicket: this.form.dontCreateTicket,
        },
      };

      axios
        .request(options)
        .then((res) => {
          msg = "Filter created successfully";
          this.sendMessage(msg);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        });
    },
    updateEmailFilter(id, msg) {
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/mailFilters/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          name: this.form.name,
          combination: this.form.combination,
          redirectionType: this.form.redirectionType,
          redirectionId: this.form.redirectionId,
          priorityId: this.form.priorityId,
          categoryId: this.form.categoryId,
          subCategoryId: this.form.subCategoryId,
          storeAttachments: this.form.storeAttachments,
          path: this.form.path,
          order: this.form.order,
          autoClose: this.form.autoClose,
          dontCreateTicket: this.form.dontCreateTicket,
        },
      };

      axios
        .request(options)
        .then((res) => {
          msg = "Email filter updated successfully";
          this.sendMessage(msg);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        });
    },
    async getSubCategories(id) {
      if (this.mailFilterEdited.length > 0) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/ticketCategories/${
              this.mailFilterEdited[6]
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.subCategories = res.data.ticket_sub_categories;
          this.isLoading = false;
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
          this.isLoading = false;
        }
      } else if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/ticketCategories/${id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.subCategories = res.data.ticket_sub_categories;
          this.isLoading = false;
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
          this.isLoading = false;
        }
      }
    },
    isEdited() {
      if (this.mailFilterEdited.length > 0) {
        this.form.name = this.mailFilterEdited[1];
        this.form.combination = this.mailFilterEdited[2];
        this.form.redirectionType = this.mailFilterEdited[3];
        this.form.redirectionId = this.mailFilterEdited[4];
        this.form.priorityId = this.mailFilterEdited[5];
        this.form.categoryId = this.mailFilterEdited[6];
        this.form.subCategoryId = this.mailFilterEdited[7];
        if (parseInt(this.mailFilterEdited[8], 10) === 1) {
          this.form.autoClose = true;
        } else {
          this.form.autoClose = false;
        }
        if (parseInt(this.mailFilterEdited[9], 10) === 1) {
          this.form.dontCreateTicket = true;
        } else {
          this.form.dontCreateTicket = false;
        }
      }
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {
    this.isEdited();
    this.getSubCategories();
  },
};
</script>

<style></style>
