<template>
  <div
    class="md:flex md:items-center md:justify-between p-5 bg-white border-t border-b"
  >
    <div class="flex-1 min-w-0">
      <h2
        class="text-2xl font-bold leading-5 body__header--text sm:text-2xl sm:truncate"
      >
        Email Filters
      </h2>
    </div>
    <div class="mt-4 flex md:mt-0 md:ml-4">
      <button
        type="button"
        @click="
          (this.openForm = !this.openForm),
            (this.mailFilterEdited = []),
            (this.title = 'New Email Filter')
        "
        class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white body__table-pagination--button"
      >
        New
      </button>
    </div>
  </div>
  <div class="flex flex-col mt-3" v-if="this.openUpdateForm || this.openForm">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div class="rounded-md overflow-hidden border bg-white">
          <NewEmailFilters
            v-on:closeForm="
              (this.openUpdateForm = false), (this.openForm = false)
            "
            :title="this.title"
            selected="Select"
            :teams="this.teams"
            :users="this.users"
            :categories="this.categories"
            :mailFilterEdited="this.mailFilterEdited"
            :key="this.mailFilterEdited"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white shadow overflow-hidden sm:rounded-md m-5">
    <ul role="list" class="divide-y divide-gray-200">
      <li
        v-for="filter in mailFilters"
        :key="filter.id"
        class="hover:bg-gray-50 grid grid-cols-12"
      >
        <a
          @click="getMailFilterDetail(filter.id)"
          class="block cursor-pointer col-span-11"
        >
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p class="text-sm font-medium encom_primary_text_dark truncate">
                {{ filter.name }}
              </p>
              <div class="ml-2 flex-shrink-0 flex">
                <p
                  v-if="filter.priorityId === 1"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
                >
                  Low
                </p>
                <p
                  v-else-if="filter.priorityId === 2"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                >
                  Medium
                </p>
                <p
                  v-else-if="filter.priorityId === 3"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                >
                  High
                </p>
                <p
                  v-else-if="filter.priorityId === 4"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                >
                  Critical
                </p>
              </div>
            </div>
            <div class="mt-2 sm:flex sm:justify-between">
              <div class="sm:flex">
                <p class="flex items-center capitalize text-sm text-gray-500">
                  <UsersIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {{ filter.redirectionType }}
                </p>
                <p
                  class="mt-2 flex items-center uppercase text-sm text-gray-500 sm:mt-0 sm:ml-6"
                >
                  <AdjustmentsIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {{ filter.combination }}
                </p>
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <CalendarIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <p>
                  Opening on
                  {{ " " }}
                  <time :datetime="filter.created_at"
                    >{{ formatDateFrench(filter.created_at) }},
                    {{ formatHour(filter.created_at) }}</time
                  >
                </p>
              </div>
            </div>
          </div>
        </a>
        <div class="flex-shrink-0 pr-2 flex place-self-center">
          <button
            @click="
              this.deleteEmailFilter(
                filter.id,
                `Delete Email Filter ${filter.name}`,
                'Do you wish to continue?',
                'Continue',
                'Go back',
                'Email filter deleted successfully'
              )
            "
            type="button"
            class="w-8 h-8 inline-flex items-center justify-center text-red-400 rounded-full bg-transparent hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            <span class="sr-only">Open options</span>
            <TrashIcon class="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
      </li>
    </ul>
  </div>
  <div v-show="this.openDetailView">
    <EmailFiltersDetail
      @open-edit-form="(...args) => this.openEditForm(...args)"
      :mailFilterDetail="this.mailFilterDetail"
      :teams="this.teams"
      :users="this.users"
      :categories="this.categories"
      :key="this.mailFilterDetail"
    />
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  CalendarIcon,
  AdjustmentsIcon,
  UsersIcon,
  DotsVerticalIcon,
  TrashIcon,
} from "@heroicons/vue/solid";
import "vue-loading-overlay/dist/vue-loading.css";
import ConfirmationDialog from "../../../components/ConfirmationDialog.vue";
import AskConfirmationDialog from "../../../components/AskConfirmationDialog.vue";
import EmailFiltersDetail from "../../../components/configuration/support/EmailFiltersDetail.vue";
import NewEmailFilters from "../../../components/configuration/support/NewEmailFilters.vue";

export default {
  title() {
    return `${this.titleTest} - ${localStorage.getItem("title")}`;
  },
  name: "EmailFilters",
  components: {
    CalendarIcon,
    AdjustmentsIcon,
    UsersIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TrashIcon,
    DotsVerticalIcon,
    EmailFiltersDetail,
    NewEmailFilters,
    ConfirmationDialog,
    AskConfirmationDialog,
  },

  data() {
    return {
      test: "sidebar.tickets.tickets",
      openForm: false,
      openNewForm: false,
      openUpdateForm: false,
      fullPage: false,
      isLoading: true,
      openDetailView: null,
      mailFilters: [],
      mailFilterDetail: {},
      mailFilterEdited: [],
      teams: [],
      title: "New Email Filter",
      users: [],
      categories: [],
      subCaterogies: [],
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
    };
  },
  methods: {
    openEditForm(...args) {
      this.mailFilterEdited = args;
      this.openForm = false;
      this.openUpdateForm = true;
      this.title = args[10];

      window.scrollTo(0, 100);
    },
    async deleteEmailFilter(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(`${this.$cookie.getCookie("API")}/api/v1/mailFilters/${id}`)
          .then((res) => {
            if (res) {
              this.sendMessage(successMsg);
            }
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              this.$store.dispatch("token", null);
              this.$router.push("/login");
            }
          });
      }
    },
    async getMailFilters() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/mailFilters`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.mailFilters = res.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    async getMailFilterDetail(id) {
      try {
        this.openDetailView = id;
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/mailFilters/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.mailFilterDetail = res.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    async getTeams() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/teams`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.teams = res.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    async getUsers() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/users`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.users = res.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    async getCategories() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/ticketCategories`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.categories = res.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
    formatHour(date) {
      const dateCreated = new Date(date);
      const formatedHour =
        ("0" + dateCreated.getHours()).slice(-2) +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedHour;
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {
    this.getMailFilters();
    this.getTeams();
    this.getUsers();
    this.getCategories();
  },
  computed: {
    titleTest() {
      return this.$t(this.test);
    },
  },
};
</script>
